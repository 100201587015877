import willMakerTheme from "../themes/willMaker"
import threesdTheme from "../themes/3sd"
import divnetTheme from "../themes/divnet"
import nolodivorceTheme from "../themes/nolodivorce"
import analyticsTheme from "./analytics"
import { getMobileNavItems as divorceMobile, navItems as divorceNavItems } from "./divorceMenus"
import {
  getMobileNavItems as analyticsMobile,
  navItems as analyticsNavItems,
} from "./analyticsMenus"
import {
  getMobileNavItemsNoDowload as willmakerMobileNoDownload,
  getMobileNavItemsWithDownload as willmakerMobileWithDownload,
  navItems as willmakerNavItems,
} from "./willmakerMenus"

export const getTheme = (themeName) => {
  switch (themeName) {
    case "threesd":
      return threesdTheme
    case "divorcenet":
      return divnetTheme
    case "nolodivorce":
      return nolodivorceTheme
    case "analytics":
      return analyticsTheme
    case "willmaker":
    default:
      return willMakerTheme
  }
}

export default getTheme

export function getMobileNavItemsNoDowload(themeName, session, divlink?) {
  switch (themeName) {
    case "threesd":
    case "divorcenet":
    case "nolodivorce":
      return divorceMobile(session, divlink)
    case "analytics":
      return analyticsMobile(session)
    case "willmaker":
    default:
      return willmakerMobileNoDownload(session, divlink)
  }
}

export function getMobileNavItemsWithDownload(themeName, session, downloadSlug, divlink) {
  switch (themeName) {
    case "threesd":
    case "divorcenet":
    case "nolodivorce":
      return divorceMobile(session, divlink)
    case "analytics":
      return analyticsMobile(session)
    case "willmaker":
    default:
      return willmakerMobileWithDownload(session, downloadSlug, divlink)
  }
}

export function getNavItems(themeName) {
  switch (themeName) {
    case "threesd":
    case "divorcenet":
    case "nolodivorce":
      return divorceNavItems
    case "analytics":
      return analyticsNavItems
    case "willmaker":
    default:
      return willmakerNavItems
  }
}

export interface NavItem {
  label: string
  href: string
  onClose?: any
  userName?: boolean
}
