import { Global } from "@emotion/react"
import configs from "./fonts"

type FontPreloadCondition = {
  weights: number[]
  format: string
}

class FontHelper {
  static renderFontFaces(name: string, weights: number[]) {
    const fonts = configs.filter((font) => name === font.family && weights.includes(font.weight))

    if (!fonts?.length) throw new Error(`[${name}] font is not found`)

    const fontFaces = fonts
      .map(
        ({ family, style, display, weight, src }) => `
          @font-face {
            font-family: "${family}";
            font-style: ${style};
            font-display: ${display};
            font-weight: ${weight};
            src: ${src.map(({ url, format }) => `url("${url}") format("${format}")`).join(", ")};
          }`
      )
      .join("\n")

    return <Global styles={fontFaces} />
  }

  static renderFontPreloads(name: string, conditions: FontPreloadCondition) {
    const fonts = configs
      .filter((font) => name === font.family)
      .filter((font) => conditions.weights.includes(font.weight))
      .map((font) => ({
        ...font,
        src: conditions.format
          ? font.src.filter(({ format }) => format === conditions.format)
          : font.src,
      }))

    return (
      <>
        {fonts.flatMap((font) =>
          font.src.map((src) => (
            <link
              rel="preload"
              href={src.url}
              key={src.url}
              as="font"
              type={src.type}
              crossOrigin="anonymous"
            />
          ))
        )}
      </>
    )
  }
}

export default FontHelper
