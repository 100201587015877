import baseTheme from "./baseTheme"
import { extendTheme } from "@chakra-ui/react"
import { mergeDeep } from "lib/utils"
import logoImage from "public/images/wm_logo.svg"

const pageOptions = {
  documents: {
    incomplete: {
      icon: "",
    },
    welcomeMessage: "",
    welcomeText: "",
  },
  thankYouModal: {
    text: "",
  },
  thankYouLowRateModal: {
    title: "",
    text: "",
  },
}

const wmGray = {
  900: "#272727",
  800: "#3A3A3A",
  700: "#505050",
  600: "#979797",
  500: "#8F8F8F",
  400: "#C9C9C9",
  300: "#E3E5E6",
  200: "#E3E5E6",
  100: "#F9F9F9",
  50: "#FAFAFA",
}

const Link = {
  baseStyle: {
    _hover: {
      textDecoration: "none",
    },
    _focus: {
      boxShadow: "none",
    },
  },
  variants: {
    brand: {
      _hover: {
        color: "brand.blue",
      },
    },
    bluelink: {
      color: "brand.blue",
      _hover: {
        color: "brand.blue",
        textDecoration: "underline",
      },
    },
  },
}

const Button = {
  baseStyle: {
    lineHeight: "24px",
    borderRadius: "4px",
    fontWeight: "bold",
    border: "2px solid",
    _hover: {
      borderWidth: "0px",
      background: "#1E91D6",
      color: "var(--chakra-colors-white)",
    },
    _focus: {
      boxShadow: "none",
    },
  },
  sizes: {
    sm: {
      fontSize: "16px",
      height: "40px",
      px: "20px",
      py: "8px",
    },
    md: {
      fontSize: "20px",
      height: "60px",
      px: "20px",
      py: "18px",
    },
  },

  variants: {
    brand: {
      color: "brand.blue",
      borderColor: "brand.blue",
    },
    gradient: {
      color: "var(--chakra-colors-white)",
      border: "none",
      background: "linear-gradient(88.59deg, #0072BB 0%, #1E91D6 100%)",
      _hover: {
        background: "linear-gradient(88.59deg, #0072BB 0%, #1E91D6 0%)",
        color: "var(--chakra-colors-white)",
      },
    },
  },
}

const borderStyle = "1px solid #dfdfdf"

const theme = extendTheme(
  mergeDeep(baseTheme, {
    options: {
      hasPhoneNumberOnMenu: false,
    },
    pageOptions,
    logoImage: {
      url: logoImage,
      width: 216,
      height: 60,
    },
    colors: {
      brand: {
        blue: "#0072BB",
        orange: "#F55D25",
        activeNav: "#0072BB",
        bullet: "#F55D25",
      },
      gray: wmGray,
    },
    components: {
      Link,
      Button,
      Container: {
        sizes: {
          xl: {
            maxW: "1148px",
            px: { base: "16px", lg: "10px", md: "24px" },
            clear: "both",
          },
        },
      },
    },
    styles: {
      global: {
        body: {
          lineHeight: "24px",
          fontWeight: "normal",
          fontSize: "16px",
          color: "gray.700",
        },

        // these styles can probably be moved over to forms4 and injected in to cp
        "body.forms4Widget section.chakra-modal__content.help-modal": {
          color: "var(--chakra-colors-gray-900)",
        },
        "body.forms4Widget section.chakra-modal__content.help-modal .chakra-modal__close-btn": {
          top: "1.4rem",
        },
        "body.forms4Widget .topicinner": {
          lineHeight: "28px",
          fontSize: "1.8rem",
        },
        "body.forms4Widget .topicinner a": {
          color: " #006ab3",
        },
        "body.forms4Widget .topicinner .bottom-action ": {
          display: "block",
          marginTop: "5px",
          marginBottom: "5px",
        },
        "body.forms4Widget .topicinner .example": {
          display: "block",
          paddingLeft: "30px",
          fontStyle: "italic",
        },
        "body.forms4Widget .topicinner div.single-br": {
          display: "block",
          marginTop: "8px",
          marginBottom: "8px",
          content: '" "',
        },
        "body.forms4Widget .topicinner div.double-br": {
          display: "block",
          marginBottom: "16px",
          content: '" "',
        },
        "body.forms4Widget .topicinner .helplist": {
          listStyle: "disc",
          paddingLeft: "30px",
        },
        "body.forms4Widget .topicinner .numlist": {
          marginTop: "8px",
          listStyle: "decimal",
          paddingLeft: "30px",
        },
        "body.forms4Widget .topicinner header.subtopic": {
          fontSize: "16px",
          fontStyle: "italic",
          fontWeight: "bold",
          marginTop: "1.5rem",
        },
        "body.forms4Widget mark.search-highlight": {
          backgroundColor: "transparent",
          color: "var(--chakra-colors-tertiary-500)",
        },

        // these styles still need investigation
        "#__forms4 .bottom-action-button-duo": {
          zIndex: "2 !important",
        },

        ".bottom-action-button-duo-inner": {
          margin: "0 auto",
          padding: "0 var(--chakra-space-6)",

          "@media screen and (max-width: 480px)": {
            width: "calc(100vw - 50px)",
          },
          "@media screen and (min-width: 670px)": {
            width: "620px",
          },
          "@media screen and (min-width: 728px)": {
            width: "90vw",
          },
          "@media screen and (min-width: 1200px)": {
            width: "1152px",
          },
        },

        ".snap-to ul": {
          marginLeft: "35px !important;",
        },
        "#__forms4": {
          fontSize: "1.8rem",
          color: "gray.900",
          lineHeight: "1.55 !important",
        },
        "#__forms4 a": {
          color: " #006ab3",
        },
        "#__forms4 a.info-button-blue": {
          color: " white",
        },
        "bottom-action a ": {
          color: " #006ab3",
        },

        "#__forms4 .chakra-input.empty-alert": {
          backgroundColor: "#ffeadf",
          borderColor: "#ffc7b2",
        },
        "#__forms4 .chakra-input.empty-alert::placeholder": {
          color: "#979797",
        },
        "#__forms4 .chakra-input.empty-alert:focus": {
          borderColor: "#ffc7b2",
          boxShadow: "none",
        },
        "#__forms4 .chakra-input, #__forms4 .chakra-button": {
          scrollMargin: "100px 0 100px 0",
        },
        // modal: wmstyle
        ".chakra-modal__close-btn.wmstyle": {
          background: "#FFFFFF",
          borderRadius: "50%",
          marginTop: "-62px",
        },
        ".chakra-modal__close-btn.wmstyle:hover": {
          background: "linear-gradient(88.59deg, #0072BB 0%, #1E91D6 0%)",
          color: "var(--chakra-colors-white)",
        },
        ".chakra-modal__close-btn.wmstyle:focus": {
          boxShadow: "none",
        },
        // Navbar
        ".chakra-menu__menuitem.main-nav": {
          color: "gray.900",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "normal",
          padding: "0",
        },
        ".chakra-menu__menuitem.main-nav:hover": {
          backgroundColor: "white",
          color: "brand.blue",
        },
        // page: home
        ".home-hero": {
          background: "gray.100",
          padding: "20px 0",
        },
        ".home-hero .title": {
          color: "gray.900",
          fontWeight: "extrabold",
        },
        ".home-hero .highlight": {
          color: "brand.orange",
          fontWeight: "normal",
        },
        ".home-hero .how-it-works": {
          color: "gray.900",
          fontWeight: "bold",
        },
        // ebook
        ".ebook-box": {
          borderWidth: "1px",
          minHeight: "128px",
          borderColor: "gray.400",
          borderRadius: "4px",
        },
        ".ebook-title": {
          color: "gray.800",
          fontSize: "22px",
          lineHeight: "28px",
          fontWeight: "bold",
        },
        ".ebook-hero": {
          background: "gray.100",
          paddingTop: "34px",
          paddingDown: "34px",
        },
        ".ebook-hero .title": {
          color: "gray.900",
          fontWeight: "extrabold",
          padding: "0",
        },
        ".ebook-hero .highlight": {
          color: "brand.orange",
          fontWeight: "normal",
          padding: "4px 0 34px",
        },
        // All Cards
        "h2.product-title": {
          color: "gray.800",
          fontSize: "22px",
          lineHeight: "28px",
          fontWeight: "bold",
        },
        // DownloadCard
        ".download-card, .download-card-disabled": {
          borderWidth: "1px",
          borderColor: "gray.400",
          borderRadius: "4px",
        },
        " .download-card-disabled": {
          backgroundColor: "gray.100",
        },
        // page: download-desktop-version
        ".d-hero": {
          background: "gray.100",
          padding: "20px 0",
        },
        ".d-hero .title": {
          color: "gray.900",
          fontWeight: "extrabold",
        },
        ".d-hero .highlight": {
          color: "brand.orange",
          fontWeight: "normal",
        },
        "d-hero .subtitle": {
          fontWeight: "bold",
          lineHeight: "24px",
        },
        ".d-detail .heading1": {
          color: "gray.900",
          fontWeight: "bold",
          width: "100%",
        },
        ".d-detail .heading2": {
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: "bold",
          width: "100%",
          paddingTop: "30px",
        },
        ".d-detail .heading3": {
          fontWeight: "bold",
          width: "100%",
          paddingTop: "24px",
        },
        ".d-detail .chakra-link.d-link": {
          color: "brand.blue",
          fontWeight: "normal",
          padding: "24px 0 0",
          border: "none",
          justifyContent: "left",
        },
        ".chakra-link.d-link:hover": {
          color: "gray.900",
        },
        ".d-detail .highlight": {
          color: "brand.orange",
          fontWeight: "600",
        },
        "ul.d-list": {
          paddingLeft: "7px",
          width: "100%",
        },
        ".d-detail .context": {
          paddingTop: "24px",
          fontWeight: "normal",
          width: "100%",
        },
        ".d-detail .grid-buttons": {
          gap: "20px",
          fontWeight: "700",
          fontSize: "20px",
          lineHeight: "24px",
          paddingTop: "40px",
        },
        ".d-detail .grid-buttons button": {
          background: "brand.blue",
          color: "white",
          minWidth: "400px",
          padding: "20px",
        },
        ".d-detail .grid-buttons button:hover": {
          background: "#1E91D6",
        },
        ".d-system-box": {
          background: "gray.100",
          padding: "54px 0",
          color: "gray.900",
        },
        ".d-system-box .chakra-heading.title": {
          fontWeight: "bold",
          textAlign: "center",
          paddingBottom: "34px",
        },
        ".d-system-box .small": {
          fontSize: "18px",
          fontWeight: "600",
        },
        ".chakra-accordion__button.compare-ac": {
          backgroundColor: "gray.100",
          border: borderStyle,
        },
        ".chakra-accordion__button.compare-ac.bt0": {
          borderTop: "0",
        },
        ".compare-ac-text": {
          fontWeight: "bold",
          fontSize: "16px",
          lineHeight: "52px",
          textAlign: "left",
          flex: "1",
        },
        ".chakra-heading.compare-title": {
          color: "gray.900",
          fontSize: "22px",
          lineHeight: "28px",
          fontWeight: "bold",
          width: "100%",
        },
        ".chakra-accordion__panel.compare-ac-panel": {
          padding: "0",
          paddingBottom: "var(--chakra-space-4)",
        },
        ".compare-ac-panel .panel-grid": {
          color: "gray.900",
          padding: 0,
        },
        ".lg-grid": {
          color: "gray.900",
          padding: 0,
        },
        ".c-header": {
          backgroundColor: "gray.100",
          fontWeight: "bold",
          color: "gray.900",
        },
        ".c-content": {
          backgroundColor: "#FFFFFF",
          fontWeight: "normal",
          color: "gray.700",
        },
        ".c-content h2": {
          fontWeight: "normal",
        },
        // ProductCard
        ".pd-card-box, .pd-card-box-disabled": {
          borderWidth: "1px",
          borderColor: "#gray.400",
          borderRadius: "4px",
          backgroundColor: "none",
        },
        ".pd-card-box-disabled": {
          backgroundColor: "gray.100",
        },
        ".pd-card-accordion": {
          backgroundColor: "gray.100",
          margin: "0",
          borderBottomRightRadius: "4px",
          borderBottomLeftRadius: "4px",
        },
        // interview items
        ".interview-item h2": {
          color: "gray.900",
          fontSize: "16px",
          lineHeight: "24px",
        },
        ".interview-box": {
          backgroundColor: "gray.100",
          borderTop: "1px",
          borderColor: "gray.400",
          borderBottomRightRadius: "4px",
          borderBottomLeftRadius: "4px",
        },
        ".additional-box": {
          borderTop: "1px",
          borderColor: "gray.400",
          borderBottomRightRadius: "4px",
          borderBottomLeftRadius: "4px",
        },
        ".additional-item h2": {
          color: "gray.900",
          fontSize: "22px",
          lineHeight: "24px",
        },
        // everplans
        ".everplansBox": {
          borderWidth: "1px",
          minHeight: "128px",
          borderColor: "gray.400",
          borderRadius: "4px",
          padding: "24px",
        },
      },
    },
  })
)

export default theme
