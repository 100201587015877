import baseTheme from "./baseTheme"
import { extendTheme } from "@chakra-ui/react"
import { mergeDeep } from "lib/utils"
import logoImage from "public/images/dn_logo.svg"

const pageOptions = {
  documents: {
    incomplete: {
      icon: "divorcenet-caution",
    },
    welcomeMessage: "Welcome to your DivorceNet Documents.",
    welcomeMessageColor: "rgba(15, 72, 128, 1)",
    welcomeText: "Here you can access any of your documents.",
    nextStepsDescription:
      "This section shows you the steps in your process filling your forms out with DivorceNet.",
  },
  thankYouModal: {
    text: "Thank you for your feedback, and thank you again for choosing DivorceNet. We're so glad to hear your online divorce needs were satisfied.<br><br>Our Partners at Trustpilot will be sending you an email soon to request a short review. Your feedback is important to us and can help others seeking online divorce solutions to make the right choice.<br><br>If you need to reach us for any reason, please don't hesitate to reach out to our customer support team at onlinedivorce@nolo.com.",
  },
  thankYouLowRateModal: {
    title:
      "And thank you again for choosing DivorceNet. We're so sorry to hear you weren't satisfied with your experience.",
    text: "Please allow us the chance to make things right for you. We're dedicated to our customers, and we stand proudly behind our 100% court approval guarantee.<br><br>Please share details of your experience with our customer satisfaction team, and we'll be in touch with you as soon as possible.",
  },
}

const divorceGray = {
  900: "#272727",
  800: "#505050",
  700: "#595959",
  600: "#979797",
  500: "#8F8F8F",
  400: "#C9C9C9",
  300: "#E3E5E6",
  200: "#E5E5E5",
  100: "#F9F9F9",
  50: "#FEFEFE",
}

const PageBackground = {
  baseStyle: {
    background: "white",
  },
  sizes: {
    base: {
      background: "linear-gradient(180deg, #F8F8F8 0%, #F2F2F2 100%);",
    },
    sm: {
      background: "linear-gradient(180deg, #F8F8F8 0%, #F2F2F2 100%);",
    },
    md: {
      background: "white",
    },
  },
}

const Link = {
  baseStyle: {
    _hover: {
      textDecoration: "none",
    },
    _focus: {
      boxShadow: "none",
    },
  },
  variants: {
    brand: {
      _hover: {
        color: "brand.blue",
      },
    },
    asklawyer: {
      width: { base: "179px", lg: "168px", md: "168px", sm: "179px" },
      height: "96px",
      background: "rgba(63, 159, 227, 0.05)",
      borderRadius: "4px",
      _hover: {
        background: "linear-gradient(93.76deg, #0F4880 0%, #0F4880 100%)",
        borderRadius: "4px",
        color: "#FFFFFF",
      },
      _focus: {},
    },
    bluelink: {
      color: "brand.blue",
      _hover: {
        color: "brand.blue",
        textDecoration: "underline",
      },
    },
  },
}

const HomeContentBox = {
  baseStyle: {
    background:
      "linear-gradient(90deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2)), url('/images/htw-divnet-background.svg') no-repeat top right",
  },
  sizes: {
    base: { background: "none" },
    sm: { background: "none" },
    md: { background: "none" },
  },
}

const DocHero = {
  baseStyle: {
    backgroundImage: "url('/images/divnet-doc-header.png')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  sizes: {
    base: {
      backgroundImage: "none",
    },
    sm: {
      backgroundImage: "none",
    },
  },
}

const Button = {
  baseStyle: {
    lineHeight: "24px",
    borderRadius: "4px",
    fontWeight: "bold",
    border: "2px solid",
    _hover: {
      borderWidth: "0px",
      background: "#1E91D6",
      color: "var(--chakra-colors-white)",
    },
    _focus: {
      boxShadow: "none",
    },
    _disabled: {
      background: "#C9C9C9",
      opacity: 1,
    },
  },
  sizes: {
    sm: {
      fontSize: "16px",
      height: "40px",
      px: "20px",
      py: "8px",
    },
    md: {
      fontSize: "20px",
      height: "60px",
      px: "20px",
      py: "18px",
    },
  },

  variants: {
    brand: {
      color: "brand.blue",
      borderColor: "brand.blue",
    },
    gradient: {
      color: "var(--chakra-colors-white)",
      border: "none",
      background: "linear-gradient(88.59deg, #0072BB 0%, #1E91D6 100%)",
      _hover: {
        background: "linear-gradient(88.59deg, #0072BB 0%, #1E91D6 0%)",
        color: "var(--chakra-colors-white)",
      },
    },
    homehero: {
      border: "none",
      background: "#0F4880",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.32)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "8px 24px",
      position: "relative",
      top: "16px",
      width: "241px",
      height: "40px",
      textColor: "#FFFFFF",
      _hover: {
        background: "#409ADD",
      },
      _focus: {},
    },
    helppage: {
      width: { base: "330px", lg: "552px", md: "534px", sm: "382px" },
      height: "60px",
      borderRadius: "4px",
      bg: "linear-gradient(93.76deg, #0F4880 0%, #0F4880 100%)",
      textColor: "#FFFFFF",
      mt: "30px",
      gap: "8px",
      _hover: {
        background: "linear-gradient(96.85deg, #409DE1 10.9%, #4675B5 79.16%)",
        _disabled: {
          background: "#C9C9C9",
        },
      },
      _focus: {},
    },
    helpmodel: {
      fontSize: "16px",
      width: { base: "369px", lg: "262px", md: "262px", sm: "369px" },
      height: { base: "24px", lg: "40px", md: "40px", sm: "24px" },
      borderRadius: "4px",
      bg: "linear-gradient(93.76deg, #0F4880 0%, #0F4880 100%)",
      textColor: "#FFFFFF",
      mr: { base: "200px", lg: "265px", md: "265px", sm: "200px" },
      _hover: {
        background: "linear-gradient(96.85deg, #409DE1 10.9%, #4675B5 79.16%)",
      },
      _focus: {},
    },
    link: {
      border: "none",
      fontWeight: "normal",
      fontSize: "smaller",
      color: "rgba(30, 145, 214, 1)",
      _hover: {
        background: "none",
        color: "inherit",
      },
      _focus: {
        background: "none",
      },
    },
  },
}

const theme = extendTheme(
  mergeDeep(baseTheme, {
    options: {
      hasPhoneNumberOnMenu: false,
    },
    pageOptions,
    logoImage: {
      url: logoImage,
      width: 216,
      height: 41,
    },
    colors: {
      brand: {
        blue: "#0072BB",
        orange: "#F55D25",
        activeNav: "#F55D25",
        bullet: "#F55D25",
      },
      gray: divorceGray,
    },
    components: {
      Link,
      Button,
      HomeContentBox,
      DocHero,
      PageBackground,
      Container: {
        sizes: {
          xl: {
            maxW: "1148px",
            px: { base: "16px", lg: "10px", md: "24px" },
            clear: "both",
          },
        },
      },
    },
    styles: {
      global: {
        body: {
          lineHeight: "24px",
          fontWeight: "normal",
          fontSize: "16px",
          color: "#505050",
        },
        // these styles can probably be moved over to forms4 and injected in to cp
        "body.forms4Widget section.chakra-modal__content.help-modal": {
          color: "var(--chakra-colors-gray-900)",
        },
        "body.forms4Widget section.chakra-modal__content.help-modal .chakra-modal__close-btn": {
          top: "1.4rem",
        },
        "body.forms4Widget .topicinner": {
          lineHeight: "28px",
          fontSize: "1.8rem",
        },
        "body.forms4Widget .topicinner a": {
          color: " #006ab3",
        },
        "body.forms4Widget .topicinner .bottom-action ": {
          display: "block",
          marginTop: "5px",
          marginBottom: "5px",
        },
        "body.forms4Widget .topicinner .example": {
          display: "block",
          paddingLeft: "30px",
          fontStyle: "italic",
        },
        "body.forms4Widget .topicinner div.single-br": {
          display: "block",
          marginTop: "8px",
          marginBottom: "8px",
          content: '" "',
        },
        "body.forms4Widget .topicinner div.double-br": {
          display: "block",
          marginBottom: "16px",
          content: '" "',
        },
        "body.forms4Widget .topicinner .helplist": {
          listStyle: "disc",
          paddingLeft: "30px",
        },
        "body.forms4Widget .topicinner .numlist": {
          marginTop: "8px",
          listStyle: "decimal",
          paddingLeft: "30px",
        },
        "body.forms4Widget .topicinner header.subtopic": {
          fontSize: "16px",
          fontStyle: "italic",
          fontWeight: "bold",
          marginTop: "1.5rem",
        },
        "body.forms4Widget mark.search-highlight": {
          backgroundColor: "transparent",
          color: "var(--chakra-colors-tertiary-500)",
        },

        // these styles still need investigation
        "#__forms4 .bottom-action-button-duo": {
          zIndex: "2 !important",
        },
        ".snap-to ul": {
          marginLeft: "35px !important;",
        },
        "#__forms4": {
          fontSize: "1.8rem",
          color: "#272727",
          lineHeight: "1.55 !important",
        },
        "#__forms4 a": {
          color: " #006ab3",
        },
        "#__forms4 a.info-button-blue": {
          color: " white",
        },
        "bottom-action a ": {
          color: " #006ab3",
        },

        "#__forms4 .chakra-input.empty-alert": {
          backgroundColor: "#ffeadf",
          borderColor: "#ffc7b2",
        },
        "#__forms4 .chakra-input.empty-alert::placeholder": {
          color: "#979797",
        },
        "#__forms4 .chakra-input.empty-alert:focus": {
          borderColor: "#ffc7b2",
          boxShadow: "none",
        },
        ".chakra-text.helpBig": {
          color: "#0F4880",
          fontWeight: "600",
          fontSize: "18.5px",
          lineHeight: "1em",
          padding: "0",
        },
        ".chakra-text.helpSmall": {
          color: "gray.900",
          fontWeight: "400",
          fontSize: "10.5px",
          lineHeight: "1em",
        },
        ".chakra-text.subtitle": {
          fontSize: "18px",
          fontWeight: "700",
          lineHeight: "24px",
          height: "24px",
        },
        ".chakra-text.subtitle.lg, .chakra-text.subtitle.xl": {
          fontSize: "16px",
        },
        "#nolopath": {
          background: "hwb(207deg 96% 0%)",
          borderTop: "none",
          borderBottom: "none",
        },
        ".cp-lawyer > .chakra-container > .chakra-stack > .chakra-stack": {
          display: "flex",
          flexFlow: "column wrap",
          position: "relative",
        },
        ".cp-lawyer > .chakra-container > .chakra-stack > .chakra-stack > div:first-of-type": {
          paddingBottom: "40px",
        },
        ".cp-lawyer > .chakra-container > .chakra-stack > .chakra-stack > div:last-child": {
          paddingBottom: "20px",
          position: "absolute",
          right: "-220px",
          bottom: "10px",
        },
        ".cp-lawyer .nolopath-theme-bare .nolopath": {
          marginBottom: "10px",
          padding: "2.4rem 0 0",
        },
        ".cp-lawyer .nolopath-theme-bare .form-questions .select-box select": {
          margin: "10px 0",
        },
        ".cp-lawyer .nolopath-theme-bare": {
          minHeight: "100px",
        },
        ".cp-lawyer .nolopath-theme-bare[data-pid='96']": {
          minHeight: "100px",
        },
        ".cp-lawyer .nolopath-theme-bare .panel-nolopath .form-title > span": {
          display: "block",
          borderBottom: "1px solid #979797",
          paddingBottom: "10px",
          marginBottom: "10px",
        },
        ".cp-lawyer .nolopath-theme-bare .panel-nolopath .form-title": {
          fontWeight: "800",
          color: "#272727",
        },
        ".cp-lawyer .nolopath-theme-bare .panel-nolopath .form-title > em": {
          fontWeight: "800",
          color: "#272727",
        },
        ".cp-lawyer .nolopath-theme-bare .panel-nolopath h3.panel-title.form-title:first-of-type > em:first-of-type":
        {
          color: "#0F4880",
          textDecoration: "underline",
        },
        ".cppage_md .cp-lawyer .nolopath-theme-bare .nolopath, .cppage_sm .cp-lawyer .nolopath-theme-bare .nolopath, .cppage_base .cp-lawyer .nolopath-theme-bare .nolopath":
        {
          marginBottom: "0",
        },
        ".cppage_md .cp-lawyer > .chakra-container > .chakra-stack > div:last-child": {
          width: "100%",
        },
        ".cppage_md .cp-lawyer > .chakra-container > .chakra-stack > div:last-child > div": {
          float: "right",
        },
        ".cppage_sm .cp-lawyer > .chakra-container > .chakra-stack > div:last-child, .cppage_base .cp-lawyer > .chakra-container > .chakra-stack > div:last-child":
        {
          width: "95%",
        },
        ".cppage_base .cp-lawyer .chakra-container, .cppage_sm .cp-lawyer .chakra-container, .cppage_base #nolopath, .cppage_sm #nolopath,":
        {
          background: "transparent",
        },
      },
    },
  })
)

export default theme
