import rfdc from "rfdc"
import { useState, useEffect } from "react"
import axios, { AxiosResponse } from "axios"

export const clone = rfdc()

export const isObject = (variable) => Object.prototype.toString.call(variable) === "[object Object]"

export const isEmptyObject = (object) => !object || Object.keys(object).length === 0

export const isEmpty = (arg: any) => {
  return (
    arg == null || // Check for null or undefined
    arg.length === 0 || // Check for empty String
    (typeof arg === "object" && Object.keys(arg).length === 0) // Check for empty Object or Array
  )
}

export const isNotEmpty = (arg: any) => !isEmpty(arg)

export const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const pluralize = (noun: string, count: number) => {
  return `${count} ${noun}${count !== 1 ? "s" : ""}`
}

// Check if array of objects ontain certain key
const checkKeyPresenceInArry = (obj, key) => obj.some((o) => Object.keys(o).includes(key))

// Sort will mutate the original obj
export const sortByKey = (obj, key) => {
  if (isEmptyObject(obj) || typeof obj.sort !== "function") return false
  if (isEmpty(key)) return false
  if (!checkKeyPresenceInArry(obj, key)) return false

  return obj.sort((a, b) => {
    const x = a[key]
    const y = b[key]
    return x < y ? -1 : x > y ? 1 : 0
  })
}

export const AxiosFetch = (url: string, timeout: number) => {
  const [data, setData] = useState<AxiosResponse | null | void>(null)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let unmounted = false
    let source = axios.CancelToken.source()
    axios
      .get(url, {
        cancelToken: source.token,
        timeout: timeout,
      })
      .catch(function (e) {
        if (!unmounted) {
          setError(true)
          setErrorMessage(e.message)
          setLoading(false)
          if (axios.isCancel(e)) {
            console.log(`request cancelled:${e.message}`)
          } else {
            console.log("another error happened:" + e.message)
          }
        }
      })
      .then((a) => {
        if (!unmounted) {
          setData(a)
          setLoading(false)
        }
      })
    return function () {
      unmounted = true
      source.cancel("Cancelling in cleanup")
    }
  }, [timeout, url])

  return { data, loading, error, errorMessage }
}

export const isNumeric = (value: any) => {
  return /^-?\d+$/.test(value)
}

export const getHighestIdObjectFromArray = (values: Array<{ id: string | number }>) => {
  return values.reduce(
    (previousValue: { id: string | number }, currentObject: { id: string | number }) => {
      return previousValue.id > currentObject.id ? previousValue : currentObject
    }
  )
}

export const sortByKeyDesc = (obj, key) => {
  if (isEmptyObject(obj) || typeof obj.sort !== "function") return false
  if (isEmpty(key)) return false
  if (!checkKeyPresenceInArry(obj, key)) return false

  return obj.sort((a, b) => {
    const x = a[key]
    const y = b[key]
    return y < x ? -1 : y > x ? 1 : 0
  })
}
