const fonts = [
  {
    family: "Nunito Sans",
    style: "normal",
    display: "swap",
    weight: 200,
    src: [
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-200-normal.woff2`,
        format: "woff2",
        type: "font/woff2",
      },
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-200-normal.woff`,
        format: "woff",
        type: "font/woff",
      },
    ],
  },
  {
    family: "Nunito Sans",
    style: "normal",
    display: "swap",
    weight: 300,
    src: [
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-300-normal.woff2`,
        format: "woff2",
        type: "font/woff2",
      },
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-300-normal.woff`,
        format: "woff",
        type: "font/woff",
      },
    ],
  },
  {
    family: "Nunito Sans",
    style: "normal",
    display: "swap",
    weight: 400,
    src: [
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-400-normal.woff2`,
        format: "woff2",
        type: "font/woff2",
      },
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-400-normal.woff`,
        format: "woff",
        type: "font/woff",
      },
    ],
  },
  {
    family: "Nunito Sans",
    style: "normal",
    display: "swap",
    weight: 600,
    src: [
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-600-normal.woff2`,
        format: "woff2",
        type: "font/woff2",
      },
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-600-normal.woff`,
        format: "woff",
        type: "font/woff",
      },
    ],
  },
  {
    family: "Nunito Sans",
    style: "normal",
    display: "swap",
    weight: 700,
    src: [
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-700-normal.woff2`,
        format: "woff2",
        type: "font/woff2",
      },
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-700-normal.woff`,
        format: "woff",
        type: "font/woff",
      },
    ],
  },
  {
    family: "Nunito Sans",
    style: "normal",
    display: "swap",
    weight: 800,
    src: [
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-800-normal.woff2`,
        format: "woff2",
        type: "font/woff2",
      },
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-800-normal.woff`,
        format: "woff",
        type: "font/woff",
      },
    ],
  },
  {
    family: "Nunito Sans",
    style: "normal",
    display: "swap",
    weight: 900,
    src: [
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-900-normal.woff2`,
        format: "woff2",
        type: "font/woff2",
      },
      {
        url: `/fonts/nunito-sans-v6/nunito-sans-latin-900-normal.woff`,
        format: "woff",
        type: "font/woff",
      },
    ],
  },
]

export default fonts
