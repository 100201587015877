import absoluteUrl from "./absoluteUrl"

export const TENANTS = {
  WILLMAKER: "willmaker",
  DIVORCENET: "divorcenet",
  ANALYTICS: "analytics",
  THREESD: "threesd",
} as const

export type POSSIBLE_TENANTS = typeof TENANTS[keyof typeof TENANTS]

export function getTenant(host?: string) {
  if (host) {
    return getTenantFromHost(host)
  }

  const baseurl = absoluteUrl()?.host
  return getTenantFromHost(baseurl)
}

export function getTenantFromHost(host) {
  var override = process.env.NEXT_PUBLIC_TENANT_OVERRIDE
  const config = require("../../krabs.config")
  const tenants = config.tenants
  const tenantsNameList = tenants.map((a) => a.name)
  if (override) {
    if (tenantsNameList.includes(override)) return tenants.override
  }
  for (var i = 0; i < tenants.length; i++) {
    const tenant = tenants[i]
    const domainlist = Object.values(tenant.domains[0])
    if (domainlist.includes(host)) {
      return tenant
    } else {
      for (let i = 0; i < domainlist.length; i++) {
        if (domainlist[i] instanceof RegExp) {
          // @ts-ignore
          if (new RegExp(domainlist[i]).test(host)) {
            return tenant
          }
        }
      }
    }
  }
  return false
}

export function getFaviconForTenant(tenant: string): string {
  switch (tenant) {
    case "threesd":
      return "/3sd.svg"
    case "divorcenet":
      return "/divnet.png"

    default:
      return "/willmaker.png"
  }
}
