import { POSSIBLE_TENANTS, TENANTS } from "./tenants"
import { Prisma } from "@prisma/client"
import db from "db"
import { InterviewObject, ResponseInterviewData } from "pages/api/v1/analytics/forms_completed"

export function getGoogleAnalyticsForTenant(tenant: string): string | undefined {
  switch (tenant) {
    case "willmaker":
      return process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_WILL_MAKER
    case "threesd":
      return process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_3SD
    case "divorcenet":
      return process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_3SD
    default:
      return undefined
  }
}

export function getGoogleTagManagerForTenant(tenant: string): string | undefined {
  switch (tenant) {
    case "willmaker":
      return process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_WILL_MAKER
    case "threesd":
      return process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_3SD
    case "divorcenet":
      return process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_3SD
    default:
      return undefined
  }
}

export function getGoogleAnalytics4ForTenant(tenant: string): string | undefined {
  // We only have Google Analytics 4 for divorcenet tenant.
  // For all other tenants we only have Google tag manager or google analytics.
  // @see https://jira.internetbrands.com/browse/LGLCP-416
  switch (tenant) {
    case "divorcenet":
      return process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_4_DIVORCE_NET
    default:
      return undefined
  }
}

export async function createPageVisit({
  email,
  page,
  tenant,
}: {
  tenant: POSSIBLE_TENANTS
  email: string
  page: string
}) {
  if (!email) throw new Error(`Email missing for method: createPageVisit on ${page}`)
  await db.analytics_page_visits.create({
    data: {
      email,
      tenant,
      page,
    },
  })
}

export async function getLoginHistory({
  email,
  tenant,
}: {
  email: string
  tenant: POSSIBLE_TENANTS | undefined
}) {
  const sqlArguments: Prisma.SelectSubset<
    Prisma.analytics_user_loginsFindManyArgs,
    Prisma.analytics_user_loginsFindManyArgs
  > = {
    orderBy: { created_at: "desc" },
    take: 100,
  }

  if (tenant) {
    sqlArguments.where = { email, tenant }
  } else {
    sqlArguments.where = { email }
  }

  return await db.analytics_user_logins.findMany(sqlArguments)
}

export async function getPageVisits({
  email,
  tenant,
}: {
  email: string
  tenant: POSSIBLE_TENANTS | undefined
}) {
  const sqlArguments: Prisma.SelectSubset<
    Prisma.analytics_page_visitsFindManyArgs,
    Prisma.analytics_page_visitsFindManyArgs
  > = {
    where: { email },
    orderBy: { created_at: "desc" },
    take: 500,
  }
  if (tenant) {
    sqlArguments.where = { email, tenant }
  }
  return await db.analytics_page_visits.findMany(sqlArguments)
}

function transformInterview(interview: InterviewObject) {
  const interviewCompletedStatus = JSON.parse(interview.interviewCompletedStatus)

  const percentageCompleted =
    (100 * interviewCompletedStatus.completed) / interviewCompletedStatus.total

  return {
    slug: interview.productSlug,
    title: interview.interviewTitle,
    questionsAnswered: interviewCompletedStatus.completed,
    totalNumberQuestions: interviewCompletedStatus.total,
    percentageCompleted: percentageCompleted.toFixed(2),
  }
}

export async function getUserFormCompletionPercentages({
  savedInterviewAnswerIds,
  tenant,
}: {
  savedInterviewAnswerIds: InterviewObject[]
  tenant: POSSIBLE_TENANTS | undefined
}) {
  const interviewsTransformed: ResponseInterviewData[] = []

  if (!tenant) {
    savedInterviewAnswerIds.forEach((interview) => {
      interviewsTransformed.push(transformInterview(interview))
    })
  } else if (tenant !== TENANTS.WILLMAKER) {
    savedInterviewAnswerIds.forEach((interview) => {
      if (Object.values(interview).includes("divorce")) {
        interviewsTransformed.push(transformInterview(interview))
      }
    })
  } else {
    savedInterviewAnswerIds.forEach((interview) => {
      if (!Object.values(interview).includes("divorce")) {
        interviewsTransformed.push(transformInterview(interview))
      }
    })
  }
  return interviewsTransformed
}
