import { Flex, Spinner } from "@chakra-ui/react"

const LoadingSpinner = () => {
  return (
    <Flex h="100vh" w="100wv" justify="center" align="center">
      <Spinner size="xl" />
    </Flex>
  )
}

export default LoadingSpinner
