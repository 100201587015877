globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"consumerportal"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

Sentry.init({
  dsn: ["production", "staging"].includes(process.env.NEXT_PUBLIC_SENTRY_ENV)
    ? process.env.NEXT_PUBLIC_SENTRY_DSN
    : null,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  release: process.env.NEXT_PUBLIC_SENTRY_ENV === "production" ?
    process.env.NEXT_PUBLIC_SENTRY_RELEASE :
    process.env.NEXT_PUBLIC_SENTRY_RELEASE + "-dev",
  tracesSampleRate: 0,
  beforeSend(event, hint) {
    const error = hint?.originalException
    if (error && typeof error !== "string") {
      switch (error.name) {
        case "AuthenticationError":
        case "AuthorizationError":
        case "NotFoundError":
        case "ChunkLoadError":
          return null
      }
    }

    if (event.exception && event.exception.values) {
      const isListenerNameError = event.exception.values.some((value) =>
        value.value.includes("Identifier 'listenerName' has already been declared")
      )
      if (isListenerNameError) {
        const scripts = Array.from(document.scripts).map((script) => script.src)
        Sentry.setContext("scripts", {
          loadedScripts: scripts,
        })

        const globalVars = Object.keys(window);
        Sentry.setContext("globals", {
          globalVariables: globalVars,
        });

        Sentry.setContext("page", {
          url: window.location.href,
          referrer: document.referrer,
        });
        Sentry.setTag("source", "listener-name-error")
      }
    }
    return event
  },
})
