import { IncomingMessage } from "http"

function absoluteUrl(req?: IncomingMessage) {
  var host = ""
  var protocol = ""

  if (typeof window !== "undefined") {
    host = window.location.host
    protocol = process.env.PROTOCOL ?? "https"
  }

  if (
    req &&
    req.headers["x-forwarded-host"] &&
    typeof req.headers["x-forwarded-host"] === "string"
  ) {
    host = req.headers["x-forwarded-host"]
  }

  if (
    req &&
    req.headers["x-forwarded-proto"] &&
    typeof req.headers["x-forwarded-proto"] === "string"
  ) {
    protocol = `${req.headers["x-forwarded-proto"]}:`
  }

  if (req && req.headers["x-vhost"] && typeof req.headers["x-vhost"] === "string") {
    host = req.headers["x-vhost"]
  }

  if (host && protocol) {
    return {
      protocol,
      host,
      origin: protocol + "://" + host,
    }
  }
}

export default absoluteUrl
