import { withBlitz } from "app/blitz-client"
import { useRouter } from "next/router"
import { useQueryErrorResetBoundary } from "@blitzjs/rpc"
import {
  AppProps,
  ErrorBoundary,
  ErrorComponent,
  ErrorFallbackProps,
} from "@blitzjs/next"
import IBConnectRedirect from "app/components/IBConnectRedirect"
import { ChakraProvider } from "@chakra-ui/react"
import getTheme from "app/core/themes/theme"
import FontHelper from "../app/components/fonts/FontHelper"
import React from "react"
import { Fragment, useEffect } from "react"
import NextNprogress from "nextjs-progressbar"
import forms4GlobalStyles from "app/core/layouts/forms4GlobalStyles"
import { getTenant } from "../app/utils/tenants"
import { getGoogleAnalyticsForTenant } from "../app/utils/analytics"
import { DevqalySDK } from "@devqaly/browser"
import IBConnectLogoutRedirect from "app/components/IBConnectLogoutRedirect"

import { Provider as StoreProvider } from "react-redux"
import { wrapper } from "app/redux/store"
import { AuthenticationError, AuthorizationError, CSRFTokenMismatchError } from "blitz"
import App, { AppContext, AppInitialProps } from 'next/app'
import FeatureToggleProvider from "app/components/common/FeatureToggle"
import TenantProvider from "app/components/common/Tenant"

type AppOwnProps = { activeFeatures: any, tenant: any }

const CustomApp = withBlitz(function ({ Component, ...rest }: AppProps & AppOwnProps) {
  const { store, props } = wrapper.useWrappedStore(rest)
  const { pageProps, activeFeatures } = props
  const tenant = rest?.tenant
  const theme = getTheme(tenant?.name)

  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()

  const googleAnalytics = getGoogleAnalyticsForTenant(tenant?.name)

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (typeof window !== "undefined") {
        // @ts-ignore
        if (typeof window.gtag !== "undefined") {
          window["gtag"]("config", googleAnalytics, {
            page_path: url,
          })
        }
      }
    }

    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events, googleAnalytics])

  useEffect(() => {
    if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_DEVQALY_ENABLED === "true") {
      const devqaly = new DevqalySDK({
        frontendUrl: "https://devqaly.internetbrands.com",
        apiUrl: "https://api-devqaly.internetbrands.com",
        projectKey: "jCbkq8PET2AvL7siNKeCpmPgKTWwCiO5cRT77YasgL3RrHHC8faTH7aie3I6",
      })

      devqaly.showRecordingButton()
    }
  }, [])

  return (
    <>
      <StoreProvider store={store}>
        {/*
         * the script tag will check if the user is using Internet Explorer
         * if the user is IE, due to the lack of support from CP,
         * the browser will show an alert message suggesting the user
         * to use a different web browser.
         */}

        <script
          dangerouslySetInnerHTML={{
            __html: `var isIE = /*@cc_on!@*/ false || !!document.documentMode;
        if(isIE)alert('Internet Explorer is no longer supported.\\n\\nPlease use the latest version of\\nChrome, Firefox, Safari or MS Edge')`,
          }}
        />
        <TenantProvider tenant={tenant}>
          <ChakraProvider theme={theme}>
            {theme.primaryFonts
              .filter(({ fontFace }) => fontFace)
              .map(({ name, weights }) => (
                <Fragment key={name}>{FontHelper.renderFontFaces(name, weights)}</Fragment>
              ))}
            <NextNprogress color="#F55D25" />
            <ErrorBoundary
              FallbackComponent={RootErrorFallback}
              onReset={useQueryErrorResetBoundary().reset}
            >
              <FeatureToggleProvider activeFeatures={activeFeatures} >
                {getLayout(<Component {...pageProps} />)}
              </FeatureToggleProvider>
            </ErrorBoundary>
          </ChakraProvider>
        </TenantProvider>
        <style jsx global>
          {forms4GlobalStyles}
        </style>
      </StoreProvider>
    </>
  )
})

// @ts-ignore
CustomApp.getInitialProps = async (
  context: AppContext
): Promise<AppOwnProps & AppInitialProps> => {
  const { req } = context.ctx;
  let tenant
  if (req) {
    tenant = getTenant(req.headers.host)
  }

  const ctx = await App.getInitialProps(context)
  const activeFeatures = (process?.env?.FEATURE_FLAGS ?? "")
    .split(",")
    .filter((flag) => flag !== "")
    .reduce((result, flag) => ({ ...result, [flag]: true }), {})
  return { ...ctx, activeFeatures, tenant }
}

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  if (error instanceof CSRFTokenMismatchError) {
    return <IBConnectLogoutRedirect />
  }
  if (error instanceof AuthenticationError) {
    return <IBConnectRedirect />
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    return (
      <ErrorComponent statusCode={error.statusCode || 400} title={error.message || error.name} />
    )
  }
}

export default CustomApp