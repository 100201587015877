module.exports = {
  tenants: [
    {
      name: "analytics",
      domains: [
        {
          development: /analytics.consumerportal.local/,
          test: /analytics.consumerportal.local/,
          // There will be two ways to identify the tenant in staging.
          // The first is with tenants that have it's own envinronment, such as
          // 1. divnet: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/divnet-stage
          // 2. threesd: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/threesd-stage
          // 3. willmaker: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/willmaker-stage
          // The second way is a special staging environment (analytics-consumerportal) that will have deployed
          // all tenants into a single environment. This is needed because we need to be able to test
          // in staging that the analytics are being shown properly when accessing https://consumerportal-analytics.nolodev.tech/
          // Now, this is possible because we have configured extra domains in requirements.yml
          // Which means that you will be able to access all tenants by just going to https://<tenant-name>-analytics.nolodev.tech/:
          // 1. https://consumerportal-analytics.nolodev.tech/
          // 2. https://willmaker-analytics.nolodev.tech/
          // 3. https://divnet-analytics.nolodev.tech/
          staging: /consumerportal-analytics/,
          production: "analytics.divorcenet.com",
        },
      ],
      paths: [
        // these paths will be loaded from the tenant; anything else will load from root
        "/",
        "/dashboard",
      ],
    },
    {
      name: "willmaker",
      domains: [
        {
          development: /consumerportal.local/,
          test: /localhost/,
          // There will be two ways to identify the tenant in staging.
          // The first is with tenants that have it's own envinronment, such as
          // 1. divnet: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/divnet-stage
          // 2. threesd: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/threesd-stage
          // 3. willmaker: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/willmaker-stage
          // The second way is a special staging environment (analytics-consumerportal) that will have deployed
          // all tenants into a single environment. This is needed because we need to be able to test
          // in staging that the analytics are being shown properly when accessing https://consumerportal-analytics.nolodev.tech/
          // Now, this is possible because we have configured extra domains in requirements.yml
          // Which means that you will be able to access all tenants by just going to https://<tenant-name>-analytics.nolodev.tech/:
          // 1. https://consumerportal-analytics.nolodev.tech/
          // 2. https://willmaker-analytics.nolodev.tech/
          // 3. https://divnet-analytics.nolodev.tech/
          staging: /willmaker-stage|willmaker-analytics/,
          production: "app.willmaker.com",
        },
      ],
      paths: [
        // these paths will be loaded from the tenant; anything else will load from root
        "/ebook",
        "/",
        "/documents",
        "/download-desktop-version",
      ],
    },
    {
      name: "threesd",
      domains: [
        {
          development: /3sd.local/,
          test: /3sd.local/,
          // There will be two ways to identify the tenant in staging.
          // The first is with tenants that have it's own envinronment, such as
          // 1. divnet: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/divnet-stage
          // 2. threesd: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/threesd-stage
          // 3. willmaker: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/willmaker-stage
          // The second way is a special staging environment (analytics-consumerportal) that will have deployed
          // all tenants into a single environment. This is needed because we need to be able to test
          // in staging that the analytics are being shown properly when accessing https://consumerportal-analytics.nolodev.tech/
          // Now, this is possible because we have configured extra domains in requirements.yml
          // Which means that you will be able to access all tenants by just going to https://<tenant-name>-analytics.nolodev.tech/:
          // 1. https://consumerportal-analytics.nolodev.tech/
          // 2. https://willmaker-analytics.nolodev.tech/
          // 3. https://divnet-analytics.nolodev.tech/
          staging: /threesd-stage|threesd-analytics/,
          production: "app.3stepdivorce.com",
        },
      ],
      paths: [
        // these paths will be loaded from the tenant; anything else will load from root
        "/",
        "/documents",
        "/help",
      ],
    },
    {
      name: "divorcenet",
      domains: [
        {
          development: /divnet.local/,
          test: /divnet.local/,
          // There will be two ways to identify the tenant in staging.
          // The first is with tenants that have it's own envinronment, such as
          // 1. divnet: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/divnet-stage
          // 2. threesd: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/threesd-stage
          // 3. willmaker: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/willmaker-stage
          // The second way is a special staging environment (analytics-consumerportal) that will have deployed
          // all tenants into a single environment. This is needed because we need to be able to test
          // in staging that the analytics are being shown properly when accessing https://consumerportal-analytics.nolodev.tech/
          // Now, this is possible because we have configured extra domains in requirements.yml
          // Which means that you will be able to access all tenants by just going to https://<tenant-name>-analytics.nolodev.tech/:
          // 1. https://consumerportal-analytics.nolodev.tech/
          // 2. https://willmaker-analytics.nolodev.tech/
          // 3. https://divnet-analytics.nolodev.tech/
          staging: /divnet-stage|divnet-analytics/,
          production: "app.divorcenet.com",
        },
      ],
      paths: [
        // these paths will be loaded from the tenant; anything else will load from root
        "/",
        "/documents",
        "/help",
      ],
    },
    {
      name: "nolodivorce",
      domains: [
        {
          development: /nolodivorce.local/,
          // There will be two ways to identify the tenant in staging.
          // The first is with tenants that have it's own envinronment, such as
          // 1. divnet: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/divnet-stage
          // 2. threesd: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/threesd-stage
          // 3. willmaker: https://ship-it.internetbrands.com/orgs/nolo/kubernetes/namespaces/willmaker-stage
          // The second way is a special staging environment (analytics-consumerportal) that will have deployed
          // all tenants into a single environment. This is needed because we need to be able to test
          // in staging that the analytics are being shown properly when accessing https://consumerportal-analytics.nolodev.tech/
          // Now, this is possible because we have configured extra domains in requirements.yml
          // Which means that you will be able to access all tenants by just going to https://<tenant-name>-analytics.nolodev.tech/:
          // 1. https://consumerportal-analytics.nolodev.tech/
          // 2. https://willmaker-analytics.nolodev.tech/
          // 3. https://divnet-analytics.nolodev.tech/
          staging: /nolodivorce-stage|nolodivorce-analytics/,
          production: "app.nolo.com",
        },
      ],
      paths: [
        // these paths will be loaded from the tenant; anything else will load from root
        "/",
        "/documents",
        "/help",
      ],
    },
  ],
  enableVhostHeader: true,
}
