import { useEffect } from "react"
import LoadingSpinner from "app/components/LoadingSpinner"

const IBConnectLogoutRedirect = () => {
  useEffect(() => {
    window.location.replace("/api/auth/logout")
  }, [])

  return <LoadingSpinner />
}

export default IBConnectLogoutRedirect
