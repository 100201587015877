import { useEffect } from "react"
import LoadingSpinner from "app/components/LoadingSpinner"

const validateEmail = (email) => {
  if (typeof email != "undefined" && email != null) {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }
  return false
}

const IBConnectRedirect = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    // @ts-ignore
    get: (searchParams, prop) => searchParams.get(prop),
  })

  useEffect(() => {
    // @ts-ignore
    const email = validateEmail(params.username) ? params.username : false
    if (email) {
      window.location.replace("/api/auth/keycloak?email=" + email)
    } else {
      window.location.replace("/api/auth/keycloak")
    }
    // @ts-ignore
  }, [params.username])

  return <LoadingSpinner />
}

export default IBConnectRedirect
