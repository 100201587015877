import { NavItem } from "./theme"

export const getMobileNavItems = (session, divlink) => {
  return [
    [
      {
        label: "Account",
        href: "/api/shopify/login",
      },
      {
        label: "Documents",
        href: "/documents",
      },
      {
        label: "Questions",
        href: divlink,
      },
    ],
    [
      {
        label: `${session.first_name} ${session.last_name}`,
        userName: true,
      },
      {
        label: `${session.email}`,
      },
    ],
    [
      {
        label: "Help",
        href: "/help",
      },
      {
        label: "Logout",
        href: "/api/auth/logout",
      },
    ],
  ]
}

export const navItems: Array<NavItem> = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Documents",
    href: "/documents",
  },
  {
    label: "Questions",
    href: "/divorceform",
  },
  {
    label: "Help",
    href: "/help",
  },
]
