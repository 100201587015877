import { createContext, useContext } from "react"

type FeatureToggleProviderProps = {
  activeFeatures: { [key: string]: boolean }
  isFeatureActive: (_) => boolean
}

export const FeatureToggleContext = createContext<FeatureToggleProviderProps>({
  activeFeatures: {},
  isFeatureActive: () => true,
})

export function FeatureToggleProvider({
  activeFeatures,
  children,
}: {
  activeFeatures: any
  children: React.ReactNode
}) {
  const isFeatureActive = (flag: string) => !!activeFeatures[flag]
  return (
    <FeatureToggleContext.Provider value={{ activeFeatures, isFeatureActive }}>
      {children}
    </FeatureToggleContext.Provider>
  )
}

export const useFeatureToggle = () => useContext<FeatureToggleProviderProps>(FeatureToggleContext)

export default FeatureToggleProvider
