import { NavItem } from "./theme"

export const getMobileNavItemsNoDowload = (session, divlink) => {
  return [
    [
      {
        label: "My Documents",
        href: "/documents",
      },
      {
        label: "eBooks",
        href: "/ebook",
      },
    ],
    [
      {
        label: `${session.first_name} ${session.last_name}`,
        userName: true,
      },
      {
        label: `${session.email}`,
      },
    ],
    [
      {
        label: "My Account",
        href: "/api/shopify/login",
      },
      {
        label: "Support",
        href: "https://www.willmaker.com/support",
      },
      {
        label: `${session.userId ? "Log Out" : "Log In"}`,
        href: `${session.userId ? "/api/auth/logout" : "/api/auth/keycloak"}`,
      },
    ],
  ]
}

export const getMobileNavItemsWithDownload = (session, downloadSlug, divlink) => {
  return [
    [
      {
        label: "My Documents",
        href: "/documents",
      },
      {
        label: "eBooks",
        href: "/ebook",
      },
    ],
    [
      {
        label: `${session.first_name} ${session.last_name}`,
        userName: true,
      },
      {
        label: `${session.email}`,
      },
    ],
    [
      {
        label: "My Account",
        href: "/api/shopify/login",
      },
      {
        label: "Support",
        href: "https://www.willmaker.com/support",
      },
      {
        label: "Download WillMaker",
        href: `/${downloadSlug}`,
      },
      {
        label: `${session.userId ? "Log Out" : "Log In"}`,
        href: `${session.userId ? "/api/auth/logout" : "/api/auth/keycloak"}`,
      },
    ],
  ]
}

export const navItems: Array<NavItem> = [
  {
    label: "My Documents",
    href: "/documents",
  },
  {
    label: "eBooks",
    href: "/ebook",
  },
  {
    label: "Support",
    href: "https://www.willmaker.com/support",
  },
]
