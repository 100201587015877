import { createSlice } from "@reduxjs/toolkit"

const interviewsSlice = createSlice({
  name: "interviews",
  initialState: {},
  reducers: {
    updateInterviews: (state, actions) => actions.payload,
    removeProductInterview: (state, actions) => {
      const interviews = state
      const interview = actions.payload
      const updatedInterviews = {
        ...interviews,
        [interview.productSlug]: interviews[interview.productSlug].filter(
          (item) => item.id != interview.id
        ),
      }
      return updatedInterviews
    },
  },
})
export const { updateInterviews, removeProductInterview } = interviewsSlice.actions
export default interviewsSlice.reducer
