import { combineReducers } from "@reduxjs/toolkit"
import interviewsSlice from "./slices/interviewsSlice"
import { HYDRATE } from "next-redux-wrapper"
import productsSlice from "./slices/productsSlice"

const rootReducer = combineReducers({
  interviews: interviewsSlice,
  products: productsSlice,
})

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    }
    return nextState
  } else {
    return rootReducer(state, action)
  }
}

export default reducer
