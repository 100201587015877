import { createSelector, createSlice } from "@reduxjs/toolkit"

const productsSlice = createSlice({
  name: "products",
  initialState: [],
  reducers: {
    updateProducts: (state, actions) => actions.payload,
  },
})
export const { updateProducts } = productsSlice.actions
export default productsSlice.reducer

const getProducts = (state) => state.products
export const getProductLimits = createSelector(getProducts, (products) =>
  products.reduce(
    (acc, { slug, interview_answer_limit }) => ({ ...acc, [slug]: interview_answer_limit }),
    {}
  )
)
