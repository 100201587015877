const primaryFonts = [
  { name: "Nunito Sans", weights: [200, 300, 600, 700, 800], fontFace: true, preload: true },
  { name: "-apple-system" },
  { name: "BlinkMacSystemFont" },
  { name: "Segoe UI" },
  { name: "Helvetica Neue" },
  { name: "Arial" },
  { name: "Noto Sans" },
  { name: "sans-serif" },
]
const mainFont = primaryFonts.map(({ name }) => name).join(",")

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-8px) translateX(5px);",
}
const theme = {
  primaryFonts,
  styles: {
    global: {
      html: {
        WebkitFontSmoothing: "auto",
        WebkitTextSizeAdjust: "100%",
        msTextSizeAdjust: "100%",
        fontFamily: "sans-serif",
        fontSize: "62.5%",
      },
      body: {
        lineHeight: "base",
        backgroundColor: "white",
        fontWeight: "light",
        fontSize: "sm",
        minWidth: "369px",
      },
    },
  },
  fontSizes: {
    xxs: "1.0rem",
    xs: "1.2rem",
    sm: "1.4rem",
    md: "1.6rem",
    lg: "2.4rem",
    xl: "2.8rem",
    "2xl": "3.2rem",
    "4xl": "3.6rem",
    default: "sm",
  },
  fonts: {
    body: mainFont,
    heading: mainFont,
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            input: {
              size: "lg",
              backgroundColor: "white",
              fontSize: "16px",
              border: "1px solid #C9C9C9",
              borderRadius: "4px",
              boxSizing: "border-box",
              height: "60px",
              left: "0px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "24px 24px 5px 14px",
              gap: "8px",
              marginTop: "16px",
              width: { base: "330px", lg: "552px", md: "534px", sm: "382px" },
            },
            textarea: {
              backgroundColor: "white",
              width: { base: "330px", lg: "552px", md: "534px", sm: "382px" },
              height: "118px",
              marginTop: "16px",
              padding: "24px 15px 10px",
            },
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label": {
              ...activeLabelStyles,
            },
            "textarea:not(:placeholder-shown) + label": {
              ...activeLabelStyles,
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              pointerEvents: "none",
              mx: 3,
              px: 2,
              my: 5,
              transformOrigin: "left top",
            },
            "label[for='description']": {
              top: 5,
            },
          },
        },
      },
    },
    Checkbox: {
      variants: {
        graybox: {
          control: {
            color: "gray.600",
            fontWeight: "bold",
            borderWidth: "3px",
            borderColor: "gray.600",
            w: 8,
            h: 8,
            _checked: {
              bg: "white",
              color: "gray.600",
              _hover: {
                bg: "white",
              },
              _disabled: {
                borderColor: "gray.200",
              },
            },
          },
          label: {
            fontSize: "lg",
          },
          icon: {
            fontSize: "1.2rem",
          },
        },
      },
    },
  },
}

export default theme
