import { configureStore } from "@reduxjs/toolkit"
import { createWrapper } from "next-redux-wrapper"
import reducer from "./rootReducer"

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: reducer,
    preloadedState,
  })
}

const makeStore = () => store
export const wrapper = createWrapper(makeStore)
export type RootState = ReturnType<typeof store.getState>
export type Store = ReturnType<typeof setupStore>
